<template>
	<div class="animated-img" :class="{loaded:isLoaded,reversed:reversed}" v-scroll="onScroll" ref="image" @mouseover="onMouseover">
		<v-img :src="src" @load="onLoaded"/>
		<div v-if="isLoaded && isVisible" class="anim-fade-in anim-dur-10">
			<img :src="src" class="animated-img__image" :alt="alt">
			<border class="animated-img__border1"/>
			<!--<border class="animated-img__border2"/>-->
		</div>
	</div>
</template>

<script>
	import Border from "@/components/border";

	export default {
		name: "animated-img",
		props: ["src", "alt", "reversed"],
		components: {
			Border
		},
		data: () => {
			return {
				isLoaded: false,
				isReversed: false,
				isVisible: false,
				restartVisibilityTimeout: null,
				canRestart: false,
				isPlayback: false
			}
		},
		/*watch: {
			isVisible(val, old) {
				if (val && !old) {
					this.restartVisibilityTimeout = setTimeout(() => {
						this.isPlayback = true;
					}, 1500);
				}
			}
		},*/
		methods: {
			onLoaded() {
				setTimeout(() => {
					this.isLoaded = true;
					this.$emit("loaded");
				}, 150);
			},
			onScroll() {
				this.checkVisibility();
			},
			onMouseover() {
				/*if (this.canRestart) {
					this.canRestart = false;
					//this.isVisible = false;
					setTimeout(()=>{
						this.isPlayback = true;
						this.isVisible = true;
					},150);
				}*/
			},
			checkVisibility() {
				if (!this.$refs.image) return;
				const rect = this.$refs.image.getBoundingClientRect();
				//const headerHeight = this.$store.state.headerHeight;
				//if (rect.bottom - this.$store.state.headerHeight > 0) {
				if ((rect.top >= 0 && rect.top < window.innerHeight)
					|| (rect.bottom > 0 && rect.top < window.innerHeight)) {
					this.isVisible = true;
				} else {
					this.isVisible = false;
				}
			}
		},
		mounted() {
			this.checkVisibility();
		}

	}
</script>

<style lang="scss">
	.animated-img {
		position: relative;
		width: 297px;
		height: 297px;

		@include up($md) {
			width: 377px;
			height: 377px;
		}

		@include up($lg) {
			width: 440px;
			height: 440px;
		}

		& > div {
			width: 100%;
			height: 100%;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.v-image {
			position: absolute;
			width: 1px;
			height: 1px;
			opacity: 0;
		}


		&__image {
			animation: animated-img__nw 6000ms infinite ease;
			animation-delay: .7s;
			/*filter: blur(4px);
			opacity: .8;
			transform: scale(1.02);*/
		}
		&__border1 {
			animation: animated-img__se 6000ms infinite ease;
			animation-delay: .7s;
			//opacity: 0.3;
		}


		/*&__border2 {
			position: relative;
			animation: animated-img__border2 6000ms infinite ease;
			animation-delay: 0.7s;
			opacity: 0.3;
		}*/

		&.reversed {
			.animated-img__image {
				animation: animated-img__se 6000ms infinite ease;
				animation-delay: .7s;
			}
			.animated-img__border1 {
				animation: animated-img__nw 6000ms infinite ease;
				animation-delay: .7s;
			}
		}
	}


	/*@keyframes animated-img__image {
		from {
			filter: blur(4px);
			transform: scale(1.02);
			opacity: .8;
		}
		10% {
			filter: blur(0px);
			transform: scale(1);
			opacity: 1;
		}
		90% {
			filter: blur(0px);
			transform: scale(1);
			opacity: 1;
		}
		to {
			filter: blur(4px);
			transform: scale(1.02);
			opacity: .8;
		}
	}*/

	@keyframes animated-img__nw {
		from {
			transform: translateX(0) translateY(0);
			/*left: 0;
			top: 0;*/
			//opacity: 0.3;
		}
		10% {
			transform: translateX(-7px) translateY(-7px);
			/*left: -7px;
			top: -7px;*/
			//opacity: 1;
		}
		90% {
			transform: translateX(-7px) translateY(-7px);
			/*left: -7px;
			top: -7px;*/
			//opacity: 1;
		}
		to {
			transform: translateX(0) translateY(0);
			/*left: 0;
			top: 0;*/
			//opacity: 0.3;
		}
	}

	@keyframes animated-img__se {
		from {
			transform: translateX(0) translateY(0);
			/*left: 0;
			top: 0;*/
			//opacity: 0.3;
		}
		10% {
			transform: translateX(7px) translateY(7px);
			/*left: 7px;
			top: 7px;*/
			//opacity: 1;
		}
		90% {
			transform: translateX(7px) translateY(7px);
			/*left: 7px;
			top: 7px;*/
			//opacity: 1;
		}
		to {
			transform: translateX(0) translateY(0);
			/*left: 0;
			top: 0;*/
			//opacity: 0.3;
		}
	}

</style>