<template>
	<section class="section--padding text-center contacts">
		<v-container>
			<h2>{{this.page.texts.HOME_CONTACT_LABEL.NAME}}</h2>
			<div class="mt-4"><a :href="`tel:${this.page.texts.HOME_CONTACT_PHONE.NAME}`">{{this.page.texts.HOME_CONTACT_PHONE.NAME}}</a></div>
			<div class="mt-4"><a :href="`mailto:${this.page.texts.HOME_CONTACT_EMAIL_1.NAME}`">{{this.page.texts.HOME_CONTACT_EMAIL_1.NAME}}</a></div>
			<div class="mt-4"><a :href="`mailto:${this.page.texts.HOME_CONTACT_EMAIL_2.NAME}`">{{this.page.texts.HOME_CONTACT_EMAIL_2.NAME}}</a></div>

			<!--<p class="limited mt-8 mx-auto">Let us know. <br>
				For collaboration, your special thoughts and orders...
			</p>-->
		</v-container>
	</section>
</template>

<script>
    export default {
        name: "contacts",
        computed: {
            page() {
                return this.$store.state.page.HomeController;
            },
        },
    }
</script>

<style lang="scss">
	.contacts {
		@include up($md) {
			padding: 130px 0;
		}

		h2 {
			margin-bottom:60px;
			@include up($md) {
				margin-bottom:90px;
			}
		}
		a {
			color: $black !important;
			font-family: $header-font-family;
			font-size: $fs-h5;
			line-height: $lh-h5;
			letter-spacing: $ls-h5;

			@include up($md) {
				font-size: $fs-h4;
				line-height: $lh-h4;
				letter-spacing: $ls-h4;
			}

			&:hover {
				color: $golden !important;
			}
		}
	}
</style>