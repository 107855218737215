<template>
    <section class="section--padding text-center text-md-start history">
        <v-container class="container--narrow">
            <h3 class="mb-10 d-md-none">{{this.page.texts.HOME_OUR_STORY.NAME}}</h3>
            <v-row justify="space-between">
                <v-col cols="12" md="6" class="pr-md-10 pr-lg-0 d-flex justify-center justify-md-start pt-md-6">
                    <animated-img :src="require('@/assets/home/history/h2.jpg')" alt="Home"
                                  :reversed="true"
                                  class="mb-8"/>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
                    <div>
                        <h3 class="mb-8 d-none d-md-block">{{this.page.texts.HOME_OUR_STORY.NAME}}</h3>
                        <span v-html="this.page.texts.HOME_OUR_STORY_TEXT.TEXT_1"></span>
                        <!--<p class="limited">
                            The roots of our story go to 2008, when Le Bonheur was born and has established it's
                            originality. 2 years later Laurent Moreno has joined the brand as a chocolatier and pastry
                            chef after a friendly meeting in the Сostes hotel lobby on rue Saint-Honoré and continued
                            in Paris.</p>
                        <p class="limited">
                            Laurent started his career at Michelin 3-star restaurant 'La tour d'Argent', working with
                            chefs Manuel Martinez and Philippe Jourdin. He worked with Pierre Hermé in legendary
                            Parisian patisserie Fauchon for 5 years opening Ladurée at Champs-Elysées with him.
                        </p>
                        <p class="limited">
                            This joint effort brought to life a unique collection of artisanal original pralines and the
                            opening of the first boutique in Paris on 148 rue Saint-Honoré caughting the fancy of
                            Parisians, professionals and city visitors at once by its interior sense and upscale flavors
                            in colourful wrappings.
                        </p>-->
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import AnimatedImg from "@/components/animated-img";

export default {
    name: "history",
    components: {
        AnimatedImg
    },
    computed: {
        page() {
            return this.$store.state.page.HomeController;
        },
    },
}
</script>

<style lang="scss">
.history {
    //background: $primary-50;
}
</style>