<template>
	<div class="flavour-info" :class="infoClass">

		<v-row>
			<v-col cols="12" md="7">
				<div class="flavour-info__grid pl-3 pr-6">
					<v-row>
						<v-col cols="12"
							   :sm="isSaleOn ? '9' : '12'"
							   class="flavour-info__grid-block text-center text-sm-left align-sm-start pt-3 pb-5">
							<div class="h5 color-black" v-html="nameHtml"></div>
							<!--<div class="h6 flavour-info__subname" v-if="subnameHtml" v-html="subnameHtml"></div>-->
							<div class="size2 mt-2 flavour-info__more">
								<span v-if="isAbout" class="a" @click="isAbout=false"><svgicon name="arrow-right"/>{{page.texts.HOME_BACK_TO.NAME}}</span>
								<span v-else class="a" @click="isAbout=true">{{page.texts.HOME_ABOUT_PRODUCT.NAME}}</span>
							</div>
						</v-col>
						<v-col v-if="isSaleOn" cols="12"
							   sm="3"
							   class="flavour-info__grid-block align-center align-sm-end pt-5 pt-sm-4 pb-5">
							<button class="flavour-info__btn h5" @click="onBuy">
								<svgicon name="shop"/>
								{{page.texts.HOME_BUY.NAME}}
							</button>
						</v-col>
					</v-row>
					<!--<v-row>
						<v-col cols="12" class="flavour-info__grid-block">
							<div class="flavour-info__controls">
									<span class="size2&#45;&#45;u"
										  :class="{'color-golden':!isAbout}"
										  @click="isAbout=false">Nutrition information</span>
								<span class="dot"></span>
								<span class="size2&#45;&#45;u"
									  :class="{'color-golden':isAbout}"
									  @click="isAbout=true">About the product</span>
							</div>
						</v-col>
					</v-row>-->
					<v-row v-if="isAbout" class="flavour-info__about">
						<v-col cols="12" class="flavour-info__grid-block text-left">
							<p class="mt-2">{{item.DETAIL_TEXT}}</p>
						</v-col>
					</v-row>
					<v-row v-else class="justify-space-between">
						<!--<v-col cols="6" sm="3" class="flavour-info__grid-block align-sm-start">
							<div class="h6">Shelf life</div>
							<div class="h6" v-html="item.SHELF_LIFE"></div>
						</v-col>-->
						<!--<v-col cols="6" sm="3" class="flavour-info__grid-block align-sm-start pl-md-9 pl-lg-15">
							<div class="h6">Temperature</div>
							<div class="h6" v-html="item.TEMPERATURE"></div>
						</v-col>-->
						<v-col cols="6" md="3" lg="2" class="flavour-info__grid-block">

							<div class="h6">{{page.texts.HOME_NET_WEIGHT.NAME}}</div>
							<div class="h6" v-html="netWeight"></div>

						</v-col>
						<v-col cols="6" md="3" lg="2" class="flavour-info__grid-block order-md-1">
							<div class="h6">{{page.texts.HOME_PRICE_PER_KG.NAME}}</div>
							<div class="h6" v-html="pricePerKg"></div>
						</v-col>
						<v-col cols="12" md="12" lg="8" class="flavour-info__grid-block order-md-3">
							<div class="h6">{{page.texts.HOME_VALUE_100.NAME}}</div>
							<p class="size2">{{nutrition}}</p>
						</v-col>
						<v-col cols="12" md="6" lg="4" class="flavour-info__grid-block --nb-lg order-md-2 order-lg-3">
							<div class="h6">{{page.texts.HOME_ALLERGENS.NAME}}</div>
							<p class="size2" v-html="item.ALLERGENS"></p>
						</v-col>
						<v-col cols="12" lg="8" class="flavour-info__grid-block --nb order-md-3">
							<div class="h6">{{page.texts.HOME_INGREDIENTS.NAME}}</div>
							<p class="size2" v-html="ingredients"></p>
						</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col cols="12" md="5">
				<div class="flavour-info__img" :class="imgClass">
					<transition name="fade-quick" mode="out-in">
						<v-img v-if="isAbout&&photoIntro" :src="photoIntro" class="image" contain :alt="item.NAME" key="image1"/>
						<v-img v-else-if="photoImage" :src="photoImage" :alt="item.NAME" key="image2"/>
						<v-img v-else src="@/assets/box/bg-lg.jpg" :alt="item.NAME" key="image3"/>
					</transition>
				</div>
			</v-col>
		</v-row>

		<div class="tri" :style="triStyle"></div>
		<svgicon name="cross" @click="onClose" class="close"/>
	</div>
</template>

<script>
	import BtnPrimary from "@/components/btn-primary";
	import {nf} from "@/utils/string";
	import Config from "@/config";
	import config from "@/config";

	export default {
		name: "flavour-info",
		components: {
			BtnPrimary,
		},
		props: ["item", "mini", "triLeft"],
		data() {
			return {
				isShopping: false,
				isAbout: false
			}
		},
		watch: {
			item() {
				this.isAbout = false;
			}
		},
		computed: {
			isSaleOn(){
				return config.IS_SALE_ON
			},
			infoClass() {
				return {'with-white': !!this.$route.query?.white};
			},
			imgClass() {
				return {'with-white': !!this.$route.query?.white};
			},
			previewText() {
				return this.item.PREVIEW_TEXT || (this.item.parent?.PREVIEW_TEXT ? "MINI " + this.item.parent?.PREVIEW_TEXT : "");
			},
			nameHtml() {
				let name = this.previewText;
				//name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
				name = name.replace(/\n/is, "<br />");
				return name;
			},
			subnameHtml() {
				if (this.item.SUBNAME) return this.item.SUBNAME;
				let choco = null;
				let name = this.previewText;
				if (name.match(/.+?(in (dark|milk).+$)/is)) {
					choco = name.replace(/.+?(in (dark|milk).+$)/is, "$1");
					//choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				}
				return choco;
			},
			photoIntro() {
				return this.item.PHOTO_INTRO || this.item.parent?.PHOTO_INTRO;
			},
			photoImage() {
				return this.item.PHOTO_IMAGE || this.item.parent?.PHOTO_IMAGE;
			},
			triStyle() {
				return {
					left: this.triLeft + "px"
				}
			},
			netWeight() {
				return this.item.NET_WEIGHT;
				/*const items = [this.item.NET_WEIGHT.replace(/,/, ".")];
				if (this.mini?.NET_WEIGHT) items.push(this.mini.NET_WEIGHT.replace(/,/, "."));
				return items.join(" / ");*/
			},
			pricePerKg() {
				return this.item.PRICE_PER_KG;
				//return nf(Number(this.item.PRICE_PER_KG?.replace(/[^\d\.,]+/,"")))+" €";
				/*const items = [this.item.PRICE_PER_KG.replace(/,/, ".")];
				if (this.mini?.PRICE_PER_KG) items.push(this.mini.PRICE_PER_KG.replace(/,/, "."));
				return items.join(" / ");*/
			},
			nutrition() {
				const texts = [];
				if (this.item.ENERGY_KJ && this.item.KCAL) texts.push("Energy " + this.item.ENERGY_KJ + " kj/" + this.item.KCAL + " kcal");
				if (this.item.FATS) texts.push("fat " + nf(this.item.FATS, 2) + " g");
				if (this.item.CARBOHYDRATES) texts.push("carbohydrates " + nf(this.item.CARBOHYDRATES, 2) + " g");
				if (this.item.PROTEINS) texts.push("proteins " + nf(this.item.PROTEINS, 2) + " g");
				return texts.join(", ");
			},
			ingredients() {
				const i = this.item.INGREDIENTS;
				return i.replaceAll(Config.ALLERGENS_REGEXP, "<b>$1</b>");
			},
                page() {
                    return this.$store.state.page.HomeController;
                },
		},
		methods: {
			onBuy() {
				this.$emit("buy", this.item);
			},
			onClose() {
				this.$emit("close", this.item);
			}
		},
	}
</script>

<style lang="scss">
	.flavour-info {
		position: relative;
		border-top: 1px solid $gray-light;
		border-bottom: 1px solid $gray-light;
		padding: 32px 40px;
		top: -16px;
		//padding-bottom: 24px;

		&.with-white {
			background: $white;

			.tri {
				background: $white;
			}
		}

		.tri {
			position: absolute;
			left: 11.5%;
			top: -6px;
			width: 32px;
			height: 32px;
			//background: $white;;
			background: linear-gradient(135deg, #fff 0, $gray-lightest 70%);
			border-top: 2px solid $gray-light;
			border-left: 2px solid $gray-light;
			transform: rotate(45deg) translateX(-50%);

			@include transition();
		}

		.close {
			position: absolute;
			z-index: 1;
			right: 20px;
			top: 20px;
			width: 16px;
			height: 16px;
			stroke: $gray-light;
			cursor: pointer;

			@include transition();

			@include up($md) {
				right: 10px;
				top: 10px;
				width: 12px;
				height: 12px;
			}

			&:hover {
				stroke: $black;
			}
		}

		&__btn {
			display: flex;
			align-items: center;
			color: $golden;
			@include transition();

			svg {
				margin-right: 12px;
				width: 24px;
				@include up($md) {
					width: 20px;
				}
				@include up($lg) {
					width: 24px;
				}
			}

			&:hover {
				color: $black;
			}

		}

		&__grid {
			text-align: left;

			&-block {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 16px 0 !important;
				text-align: left;
				align-items: flex-start;
				border-bottom: 1px solid $gray-light;

				@include up($sm) {
					padding: 16px 40px !important;
				}
				@include up($md) {
					padding: 16px 0 !important;
				}

				div.h6 {
					color: $black;
				}

				&.--nb {
					border-bottom: 0;

					&-lg {
						border-bottom: 1px solid $gray-light;
						@include up($lg) {
							border-bottom: 0;
						}
					}
				}

			}
		}

		&__subname {
			color: $golden !important;

			br {
				display: none;
			}
		}

		&__img {
			position: relative;
			width: 100%;
			height: 320px;
			border-radius: 5px;
			overflow: hidden;

			@include up($sm) {
				height: 480px;
			}

			@include up($md) {
				height: 100%;
				min-height: 400px;
			}

			&.with-white {
				//padding: 5%;
				background: $white;

				.v-image.image {
					width: 90%;
					max-width: 90%;
					height: 90%;
				}
			}

			.v-image {
				@include up($md) {
					position: absolute;
					left: 50%;
					top: 50%;
					width: 100%;
					height: 100%;
					transform: translateX(-50%) translateY(-50%);
				}
			}
		}

		&__more {

			span {
				position: relative;
				display: inline-flex;
				align-items: center;

				svg {
					width: 12px;
					transform: rotate(180deg);
					margin-right: 8px;
				}

				@include up($lg) {
					margin: 0;
				}
			}

		}

		/*&__controls {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $black;

			span {
				cursor: pointer;
				@include transition();

				&:hover {
					opacity: 0.7;
				}
			}
		}*/

		&__about {
			.h6 {
				color: $black;
			}
		}
	}
</style>