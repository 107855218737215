<template>
    <section class="section--padding about">
        <v-container class="container--narrow text-center text-md-start">
            <h2 class="mb-8">Le Bonheur original pralines collection</h2>

            <div class="d-flex flex-column align-center align-md-start">
                <p class="limited">
                    Our collection of praline consists of 14 original flavours each of it is a special and peculiar mix
                    of ingredients.<br>
                    All pralines are enriched with palpable pieces of nuts and berries — it becomes possible due to the
                    handcraft process.
                </p>
            </div>
        </v-container>
    </section>
</template>

<script>
export default {
    name: "about",
}
</script>

<style lang="scss">
</style>